import Vue from 'vue'

export default {
  get(locationId) {
    return Vue.http.get(`location/${locationId}`)
  },
  getByAddress(addressId) {
    return Vue.http.get(`location/address/${addressId}`)
  },
}
