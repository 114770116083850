import Vue from 'vue'
import * as Sentry from '@sentry/browser'

// Sentry
if (process.env.NODE_ENV === 'production') {
  if (window) {
    window.OvationSentry = Sentry
  }
  Sentry.init({
    dsn: 'https://fc76be78e2904c189190c0da75ad83cc@sentry.io/1399595',
    integrations: [
      new Sentry.Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
  })
}

import VueResource from 'vue-resource'
import VueMask from 'v-mask'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueClipboard from 'vue-clipboard2'
import VueMq from 'vue-mq'
import App from './App.vue'
import router from './router'
import store from './store'
import { interceptor } from './lib/http'
import Vue2Filters from 'vue2-filters'
import Mixpanel from 'mixpanel-browser'
import Config from '@/config'
import { i18n } from './setup/i18n-setup'

Vue.use(VueResource)
Vue.use(VueClipboard)
Vue.component(VueCountdown.name, VueCountdown)
Vue.use(Vue2Filters)
Vue.use(VueMask)
Vue.use(require('vue-moment'))
Vue.config.productionTip = false
Vue.http.interceptors.push(interceptor)

Vue.use(VueMq, {
  breakpoints: {
    sm: 576,
    md: 768,
    lg: Infinity,
  },
})

// Init mixpanel
const { mixpanel } = Config
Mixpanel.init(mixpanel)
Vue.prototype.$mixpanel = Mixpanel

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
