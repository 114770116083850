import I18nService from '../services/I18nService'
import { loadLanguage, i18n } from '../setup/i18n-setup'

const defaultSurvey = {
  'pick_survey_location.title': 'Choose a location to rate your experience on',
  'pick_survey_location.search.placeholder': 'Search',
  'leave_survey.rating.greeting': 'How was your experience?',
  'leave_feedback.form.title.rating_1': 'Oh no! Could you tell us about it?',
  'leave_feedback.form.title.rating_2': 'Oh no! Could you tell us about it?',
  'leave_feedback.form.title.rating_3': 'What could we do to improve?',
  'leave_feedback.form.title.rating_4': 'Thanks! How can we improve?',
  'leave_feedback.form.text_area.placeholder': 'Leave your comments here...',
  'leave_feedback.form.detail_meter.title': 'Detail Strength',
  'leave_feedback.form.button.submit': 'Send to Management',
  'giveaway.checkin.title': 'Help us personalize your service.',
  'giveaway.incentive.call_to_action.instant':
    'Enter your information to receive',
  'giveaway.incentive.call_to_action.sweepstakes': 'How can we keep in touch?',
  'giveaway.incentive.call_to_action.default': 'How can we keep in touch?',
  'giveaway.legal_text.grubhub':
    'By clicking {0}, I consent to receive phone calls, text messages, and/or emails from {1}, about my order at the phone number/email address I provided. I understand these calls/messages/emails may be generated using an automated technology.',
  'giveaway.legal_text.default':
    'By clicking {0}, I consent to receive phone calls, text messages, and/or emails from {1}, regarding their products and services, at the phone number/email address I provided. I understand these calls/messages/emails may be generated using an automated technology.',
  'giveaway.button.submit': 'Submit',
  'entered.thank_you': 'Thank you!',
  'entered.feedback_sent_confirmation':
    'Your feedback was sent to management for review.',
  'entered.incentive_confirmation.instant':
    'Look out for a text from us shortly with',
  'entered.incentive_confirmation.sweepstakes': "You've been entered to win",
  'entered.incentive_confirmation.default': "You've been entered to win",
  'entered.sweepstakes_winner_notice':
    "You'll receive a text message if you win.",
  'entered.platforms_list.title': 'Visit us online',
  'leave_review.thank_you': 'Thanks for helping us!',
  'leave_review.invite': 'Would you mind sharing your experience?',
}

const defaultRedeem = {
  'redemption_start.additional_terms': 'See additional terms',
  'redemption_start.expired': 'Expired on {0}',
  'redemption_start.button.submit': 'Redeem Now',
  'redemption_start.limit': 'Can only be redeemed once.',
  'redemption_start.expiration': 'Offer expires on {0}',
  'redemption_confirm.verify': 'Are you sure?',
  'redemption_confirm.limit_warning':
    'You will have {0} to use this screen at checkout.',
  'redemption_confirm.time_limit': '15 minutes',
  'redemption_confirm.button.submit': "Yes, I'm ready to redeem",
  'redemption_location.online': 'Redeem Online',
  'redemption_location.store': 'Redeem In-store',
  'redemption_redeemed.already_redeemed': 'Already Redeemed',
  'redemption_redeemed.footer.in_store':
    'Show this screen to the cashier at checkout.',
  'redemption_redeemed.footer.online':
    'Apply the code above to an online order.',
  'redemption_completion.button.order': 'Order Here',
  'redemption_completion.expires': 'expires in',
}

const defaultCommon = {
  'common.single_word.required': 'required',
  'common.single_word.optional': 'optional',
  'common.single_word.submit': 'submit',
  'common.single_word.code': 'code',
  'common.phrase.thank_you': 'Thank you',
  'common.label.mobile_number': 'Mobile number',
  'common.label.full_name': 'Full name',
  'common.label.email_address': 'Email address',
  'common.utility.copy': 'tap to copy',
  'common.utility.copied': 'copied',
  'common.errors.casual.link_expired':
    'Uh oh! Looks like this link is expired.',
  'common.errors.casual.default': 'Uh oh! Looks like something went wrong.',
  'common.errors.casual.default_retry':
    'Uh oh! Looks like something went wrong. Please try again later.',
  'common.errors.causal.not_found': 'Sorry, but we are unable to locate {0}',
}

export default {
  namespaced: true,
  state: {
    languages: [], // array of objects. [{ language: string, messages: obj }]
    flowTemplates: {
      survey: ['OVATION.SURVEY', 'OVATION.COMMON'],
      redeem: ['OVATION.REDEEM', 'OVATION.COMMON'],
      common: ['OVATION.COMMON'],
    },
    currentFlow: null,
    lastTemplateFilters: {},
  },
  getters: {
    selectLanguage: (state) => (language) =>
      state.languages?.find((l) => l.language === language),
    selectLanguageMessages: (_, getters) => (language) =>
      getters.selectLanguage(language)?.messages,
    selectFlowTemplates: (state) => state.flowTemplates,
    selectLoadedLanguage: (_) => i18n.locale,
    selectCurrentFlow: (state) => state.currentFlow,
    selectLastTemplateFilters: (state) => state.lastTemplateFilters,
  },
  mutations: {
    SET_LANGUAGES(state, language) {
      state.languages = [language]
    },
    SET_CURRENT_FLOW(state, flow) {
      state.currentFlow = flow
    },
    SET_LAST_TEMPLATE_FILTERS(state, filters) {
      state.lastTemplateFilters = filters
    },
  },
  actions: {
    async fetchLanguage(
      { commit, getters },
      { locationId, companyId, language, source, region, flow },
    ) {
      if (!flow) {
        commit('SET_CURRENT_FLOW', 'common') // if no flow load common template for errors
      } else if (flow !== getters.selectCurrentFlow) {
        commit('SET_CURRENT_FLOW', flow)
      } else if (
        getters.selectLastTemplateFilters?.locationId &&
        getters.selectLastTemplateFilters?.locationId === locationId
      ) { // this 'if' is for getting more specific templates after location is selected
        return
      }

      commit('SET_LAST_TEMPLATE_FILTERS', {
        locationId,
        companyId,
        language,
        source,
        region,
        flow,
      })

      // split language parts into language/region
      if (!language) {
        language = window.navigator.language
        const lang_region = language.split('-')
        language = lang_region[0]
        region = lang_region[1]
      }

      try {
        const response = await I18nService.listTemplates({
          ...(companyId ? { companyIds: [companyId] } : {}),
          ...(locationId ? { locationIds: [locationId] } : {}),
          languages: [language],
          // regions: [region],
          types: getters.selectFlowTemplates[getters.selectCurrentFlow],
          sources: source && Array.isArray(source) ? source : [source],
        })
        const templates = response.body.data.translationTemplates
        if (!templates.length) {
          throw new Error('No templates found - using defaults')
        }
        const allMappedStrings = templates.reduce((acc, curr) => {
          acc = { ...acc, ...curr.stringsMap }
          return acc
        }, {})

        commit('SET_LANGUAGES', {
          language,
          messages: allMappedStrings,
        })
      } catch (error) {
        console.log('Setting Default Language', error)
        commit('SET_LANGUAGES', {
          language,
          messages: { ...defaultSurvey, ...defaultRedeem, ...defaultCommon },
        })
      }
      loadLanguage(language, getters.selectLanguageMessages(language))
    },
  },
}
